/* Tablet (Portrait, Landscape), Mobile (Landscape) */
@media only screen and (max-width: 1224px) {
    .icon-tooltip {
        top: -110%;
        left: 8%;

        @media only screen and (min-width: 480px) {
            &:after {
                left: 40%;
                transform: rotate(0);
            }
        }
    }

    // Per template

    [data-template="header"] {
        nav {
            .sub-menu {
                a {
                    &:hover {
                        background-color: skyblue !important;
                    }
                }
            }
        }

        @media only screen and (min-width: 480px) {
            .wpml-ls-current-language {
                > a {
                    pointer-events: none;
                }
            }
        }
    }
}

/* Mobile (Portrait) */
@media only screen and (max-width: 480px) {
    .icon-tooltip {
        left: 30%;

        &:after {
            top: 80%;
            right: -20%;
        }
    }
    
    // Per template

    [data-template="header"] {
        justify-content: center !important;
        padding: 0;

        

        .optional-title {
            position: absolute;
            z-index: -1;
            font-size: 48px;
        }
        .logo--ruijter__bg {
            &.optional-title{
                position: relative;
            }
        }
        .logo--ruijter {
            height: 50px;
            image {
                width: 100%;
            }
        }
        
        .mobile-toggle-wrapper {
            width: 100%;
            display: flex !important;

            button {
                background-color: #fff;
                padding: 10px 20px;
                font-size: 1em;
                border: 0;

                span {
                    font-weight: 400;
                }

                .icons {
                    width: 1em;
                    height: 1em;
                    margin-left: .5em;
                }

                i {
                    transition: all calc(300 * 1ms) cubic-bezier(.42, .01, .58, 1);
                    right: 0;

                    &.fa-times {
                        opacity: 0;
                    }
                }

                &.collapsed {
                    .fas {
                        transform: rotate(360deg);
                    }

                    .fa-bars {
                        opacity: 0;
                    }

                    .fa-times {
                        opacity: 1;
                    }
                }
            }
        }

        nav {
            pointer-events: none;
            opacity: 0;
            width: 100%;
            transition: opacity .5s;

            > ul {
                flex-wrap: wrap;

                .menu-item {
                    flex-basis: 100%;
                    text-align: center;
                    border-bottom: 1px solid lightgray;

                    a {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        min-height: 65px;
                        color: #212934 !important;
                        background-color: #fff;
                        border-radius: 0px !important;
                    }

                    &.menu-item-has-children {
                        width: calc(100% - 65px);
                        position: relative;

                        > a {
                            position: relative;
                        }

                        .sub-menu {
                            position: absolute;
                            width: 100%;
                        }

                        &.collapsed {
                            .sub-menu {
                                opacity: 1 !important;
                                max-height: 500px !important;
                            }

                            &:after {
                                transform: rotate(180deg);
                            }
                        }

                        &:hover {
                            > .sub-menu {
                                opacity: 0;
                                max-height: 0;
                            }
                        }

                        &:after {
                            transition: transform .3s cubic-bezier(.42, .01, .58, 1);
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 0;
                            right: 0;
                            height: 65px;
                            width: 65px;
                            font-family: "Font Awesome 6 Free";
                            font-weight: 900;
                            content: "\f063";
                        }
                    }

                    &.current_page_item {
                        a {
                            background-color: #f9f9fb;
                            color: var(--selected-menu-color) !important;  
                        }
                    }
                }
            }

            &.collapsed {
                pointer-events: all;
                opacity: 1;
            }
        }
    }

    [data-template="footer"] {
        padding: 20px;

        .site-title {
            text-align: center;
        }

        .contact-info {
            flex-wrap: wrap;
            justify-content: center !important;
            padding: 0;

            .address, .tel-email {
                flex-basis: 55%;
            }

            .social {
                margin: 20px 0;
            }

            .social, .brands {
                flex-basis: 100%;
            }
        }

        .legal {
            padding: 0;
            
            ul {
                flex-wrap: wrap;
                justify-content: space-between !important;

                li {
                    &:before {
                        content: none !important;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}